<template>
  <div>
    {{message}}, 請關閉此頁<br>
    {{$route.query}}
  </div>
</template>

<script>
import requestApi from '@/lib/http';

export default {
  name: 'LinepayCancel',
  data() {
    return {
      message: '',
      status: null,
    };
  },
  methods: {
    async sendData() {
      const data = this.$route.query;

      const { status, message } = await requestApi('order.linePayCancel', {
        orderId: data.orderId,
      });

      if (status) {
        this.status = true;
        this.message = message;
        // window.close();
        this.$router.push({
          name: 'CartComplete',
          query: {
            status: false,
            message,
          },
        });
      } else {
        this.status = false;
        this.message = message;
        this.$router.push({
          name: 'CartComplete',
          query: {
            status: false,
            message,
          },
        });
      }
    },
  },
  created() {
    this.sendData();
  },
};
</script>
